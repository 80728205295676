export const useItemize = ({
    replace,
    oldLineItems,
    fields,
    amountField = 'amount',
    vatField = 'tax_rule_id',
}) => {
    const { getLineItems } = useItemizeInitialize()

    const toSingleView = () => {
        const lineItems = unref(oldLineItems)
        if (!lineItems || lineItems.length < 1) {
            replace([unref(fields)[0].value])
            return
        }

        replace(getLineItems(lineItems, false, amountField))
    }

    const toItemize = () => {
        const lineItems = unref(oldLineItems)
        if (!lineItems || lineItems.length < 1) {
            return
        }

        const firstLineItem = lineItems[0] ?? null
        if (!firstLineItem) {
            return
        }

        replace(unref(oldLineItems))
    }

    const canBackSingleView = computed(() => {
        const lineItems = unref(oldLineItems)
        if (!lineItems || lineItems.length < 1) {
            return true
        }

        const firstLine = lineItems[0] ?? null
        if (!firstLine) {
            return true
        }

        const vat = firstLine[vatField] ?? null
        return lineItems.every((f) => f[vatField] === vat)
    })

    return {
        canBackSingleView,
        toSingleView,
        toItemize,
    }
}

export const useItemizeInitialize = () => {
    const getLineItems = (
        lineItems,
        itemize = true,
        amountField = 'amount'
    ) => {
        if (itemize) {
            return lineItems
        }

        let firstLineItem = lineItems[0] ?? null
        if (!firstLineItem) {
            return
        }

        firstLineItem = {
            ...firstLineItem,
            [amountField]: lineItems.reduce(
                (cur, i) => cur + i[amountField],
                0.0
            ),
        }

        return [firstLineItem]
    }

    return { getLineItems }
}
